
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import $ from 'jquery';
import { getStayRoom } from '@/api/stayRoom';
import { setStayReservation } from '@/utils/cookies';
import Slick from 'vue-slick';
import Calendar from '@/components/Calendar/index.vue';
import { Stay } from './stay';

@Component({
  components: {
    Calendar,
    Slick,
  },
})

export default class extends Vue {
  mounted() {
    this.detailQuery = {
      ...this.detailQuery,
      ...this.$route.query,
    };
    this.formQuery = {
      ...this.formQuery,
      ...this.$route.query,
      range: [this.$route.query.startDate, this.$route.query.endDate],
    };
    this.getStayRoom();
    this.slickLoading();
  }

  private slickLoad = false;

  private isLoading = true;

  private slickLoading() {
    // console.log('시작');
    this.isLoading = true;
    window.setTimeout(() => {
      this.isLoading = false;
      this.slickLoad = true;
      // console.log('종료');
    }, 3000);
  }

  private loading = true;

  private datePickerVisible = false;

  private currentIndex = 1;

  private standardPeopleFlag = false;

  private room: any = {
    name: '',
    introduction: '',
    options: [],
    images: [],
    price: 0,
    checkIn: '',
    checkOut: '',
    parkingInfo: '',
    surroundInfo: '',
    additionalPeopleInfo: '',
    refundPolicyInfo: '',
    confirmationInfo: '',
    standardPeople: 0,
    maxPeople: 0,
    phoneInquiryStatus: '',
    usable: false,
    closeStatus: true,
    usageInstruction: '',
    certMarkStatus: 0,
  }

  private filterDefault: any = {
    date: '',
    personnel: '성인2명 / 아동1명',
    personnelAdult: 2,
    personnelChild: 1,
    village: '',
  }

  private noScr() {
    document.documentElement.classList.add('noscr');
  }

  private scr() {
    document.documentElement.classList.remove('noscr');
  }

  private handleClickAccomodationSlide(str: string) {
    const slick = (this.$refs.accomodationBanner as any);
    if (str === 'next') {
      slick.next();
    } else {
      slick.prev();
    }
  }

  private mainslickOptions = {
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 400,
    // prevArrow: $('.accomodation__prev'),
    // nextArrow: $('.accomodation__next'),
    // draggable: true,
    pauseOnHover: true,
    arrows: false,
    // dots: true,
  };

  private apiUrl = process.env.VUE_APP_COMMON_API;

  private calendarData = []; // 달력 일별 데이터

  private selectedDay: string | null = '';

  private selectedMonth = moment().format('YYYY-MM');

  private calendarLoading = true;

  private stayOptionList = [];

  private cardArray = [];// 슬라이드 카드가 담길 배열 mounted에서 담아준다.

  private slideCoord = 0;// 옆으로 움직일 값, (슬라이드의 마진,패딩값 포함된 width)

  private slideWidth = 340;

  private slideCount = 5;// 전체 슬라이드 개수

  private currentSlide = 0;

  private stayDetail = [];

  private slides(data : any) {
    this.stayOptionList = data.options;
  }

  private handleChangeMonth(yearMonth: any) {
    this.selectedMonth = moment(yearMonth).format('YYYY-MM');
    this.calendarData = [];
  }

  private handleChoiceDate(startDate: string, endDate: string) {
    this.selectedDay = startDate;
    this.formQuery.range = [startDate, endDate];
  }

  private detailQuery: any = {
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    adult: 2,
    child: 0,
  }

  private formQuery: any = {
    range: [moment().format('YYYY-MM-DD'), moment().add(1, 'days').format('YYYY-MM-DD')],
    adult: 2,
    child: 0,
  }

  private getStayRoom() {
    this.loading = true;
    getStayRoom(this.$route.params.roomIdx, this.detailQuery).then((res) => {
      this.room = res.data;
      console.log(res.data);
      this.loading = false;
      this.slides(res.data);
      this.formattedBasicInfo(this.room);
    }).catch(() => {
      this.$message.error('객실 정보를 불러오는데 실패했습니다.');
      this.$router.push({ name: 'StayIndex' });
    });
  }

  private roomSplit = {
    parkingInfo: [] as Object[],
    surroundInfo: [] as Object[],
    additionalPeopleInfo: [] as Object[],
    refundPolicyInfo: [] as Object[],
    confirmationInfo: [] as Object[],
    usageInstruction: [] as Object[],
  }

  private formattedBasicInfo(stay: Stay) {
    if (stay) {
      this.roomSplit.usageInstruction = stay.usageInstruction ? stay.usageInstruction.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.roomSplit.parkingInfo = stay.parkingInfo ? stay.parkingInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.roomSplit.surroundInfo = stay.surroundInfo ? stay.surroundInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.roomSplit.additionalPeopleInfo = stay.additionalPeopleInfo ? stay.additionalPeopleInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.roomSplit.refundPolicyInfo = stay.refundPolicyInfo ? stay.refundPolicyInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.roomSplit.confirmationInfo = stay.confirmationInfo ? stay.confirmationInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
    }
  }

  private handleClickSlick(refName: string, type: string) {
    if (this.$refs[refName]) {
      const reference: any = this.$refs[refName];
      if (type === 'prev') reference.prev();
      if (type === 'next') reference.next();
    }
  }

  private getMaxAdult() {
    return this.room.maxPeople - this.formQuery.child;
  }

  private getMaxChild() {
    return this.room.maxPeople - this.formQuery.adult;
  }

  private getFewDays(start: any, end: any) {
    let fewDays = 1;
    const startDate = moment(start, 'YYYY-MM-DD');
    const endDate = moment(end, 'YYYY-MM-DD');
    fewDays = endDate.diff(startDate, 'days');
    return fewDays;
  }

  private handleResetFormQuery() {
    this.formQuery = {
      adult: this.detailQuery.adult,
      child: this.detailQuery.child,
      range: [this.detailQuery.startDate, this.detailQuery.endDate],
    };
  }

  private handleApplyFormQuery() {
    this.detailQuery = {
      ...this.detailQuery,
      adult: this.formQuery.adult,
      child: this.formQuery.child,
      startDate: this.formQuery.range[0],
      endDate: this.formQuery.range[1],
    };
    this.datePickerVisible = false;
    this.getStayRoom();
  }

  private standardPeopleClick(room : any) {
    if ((Number(this.detailQuery.adult) + Number(this.detailQuery.child)) > Number(room.standardPeople)) {
      this.standardPeopleFlag = true;
    } else {
      this.handleReservePage(room);
    }
  }

  private standardPeopleClose() {
    this.standardPeopleFlag = false;
  }

  private handleReservePage(room: any) {
    setStayReservation({
      roomIdx: this.$route.params.roomIdx,
      startDate: this.detailQuery.startDate,
      endDate: this.detailQuery.endDate,
      adult: this.detailQuery.adult,
      child: this.detailQuery.child,
    });
    this.$router.push({
      name: 'RoomReserve',
      params: {
        roomIdx: this.$route.params.roomIdx,
      },
    });
  }

  private handleDatePicker() {
    this.datePickerVisible = true;
    this.selectedDay = this.detailQuery.startDate;
    setTimeout(() => {
      const calendarRef: any = this.$refs.calendar;
      if (calendarRef) calendarRef.setDate(new Date(this.detailQuery.startDate), new Date(this.detailQuery.endDate));
      this.calendarData = [];
    }, 100);
  }

  private carouselChange() {
  //   this.arrowBtn = document.querySelectorAll('.el-carousel__arrow');
  //   if (this.arrowBtn) {
  //     // NodeList를 forEach로 반복합니다.
  //     this.arrowBtn.forEach(arrow => {
  //       arrow.children[0].addEventListener('click', this.handleArrowLeftClick.bind(this));
  //       arrow.children[1].addEventListener('click', this.handleArrowRightClick.bind(this));
  //     });
  //   }
  //   // 추가로 필요한 로직이 있으면 여기에 작성합니다.
    if (this.room.images.length <= this.currentIndex) {
      this.currentIndex -= 1;
    } else {
      this.currentIndex += 1;
    }
  }

  private slickOptions = {
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 400,
    prevArrow: $('.iconslick-prev'),
    nextArrow: $('.iconslick-next'),
    draggable: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  // // 이벤트 핸들러 함수
  // private handleArrowLeftClick() {
  //   console.log('Left arrow clicked!');
  //   // 좌측 화살표를 클릭할 때 수행할 로직을 추가합니다.
  // }

  // private handleArrowRightClick() {
  //   console.log('Right arrow clicked!');
  //   // 우측 화살표를 클릭할 때 수행할 로직을 추가합니다.
  // }
}
